import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/viewer',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./views/root/root.module').then(m => m.RootModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard], // Only logged users allowed
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
