export class LoggedUser {
  id: string;
  name: string;
  email: string;
  pictureUrl: string;

  private _authObject: firebase.User;
  public get authObject(): firebase.User {
    return this._authObject;
  }

  constructor(firebaseUser: firebase.User) {
    this.id = firebaseUser.uid;
    this.name = firebaseUser.displayName;
    this.email = firebaseUser.email;
    this.pictureUrl = firebaseUser.photoURL;
    this._authObject = firebaseUser;
  }
}
