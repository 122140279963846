import { Injectable } from '@angular/core';

import { ReplaySubject, Observable, from, throwError, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { TherapistsService } from '@app/services/therapists.service';
import { LoggedUser } from '@app/models/logged-user';

const defaultLoginRedirect = '/dashboard/viewer';

export const Errors = {
  IncompleteDetails: 'incompleteDetails'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedUser$: Subject<LoggedUser> = new ReplaySubject(
    1 /* buffer size */
  );

  public redirectUrl: string;
  public defaultRedirectUrl = defaultLoginRedirect;

  constructor(
    private afAuth: AngularFireAuth,
    private therapistsService: TherapistsService
  ) {
    this.observeAuthStateChanges();
  }

  /*
   * Public API
   */

  register(
    name: string,
    email: string,
    password: string
  ): Observable<LoggedUser> {
    if (!name || !email || !password) {
      return throwError(new Error(Errors.IncompleteDetails));
    }
    return this.createFirebaseAccount(email, password).pipe(
      map(userCredential => userCredential.user),
      switchMap(this.completeFirebaseAccountDetails({ name })),
      map(firebaseUser => new LoggedUser(firebaseUser)),
      switchMap(user =>
        this.therapistsService.newTherapist(user).pipe(
          switchMap(_ => this.logout()),
          map(_ => user)
        )
      )
    );
  }

  login(email: string, password: string): Observable<LoggedUser> {
    if (!email || !password) {
      return throwError(new Error(Errors.IncompleteDetails));
    }
    return this.signInWithFirebase(email, password).pipe(
      map(userCredential => new LoggedUser(userCredential.user))
    );
  }

  logout(): Observable<any> {
    return from(this.afAuth.auth.signOut());
  }

  /*
   * Private methods
   */

  private observeAuthStateChanges() {
    this.afAuth.auth.onAuthStateChanged(firebaseUser => {
      const loggedUser = firebaseUser ? new LoggedUser(firebaseUser) : null;
      this.loggedUser$.next(loggedUser);
      this.therapistsService.setLoggedUser(loggedUser);
    });
  }

  private createFirebaseAccount(
    email: string,
    password: string
  ): Observable<auth.UserCredential> {
    return from(
      this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    );
  }

  private completeFirebaseAccountDetails(details: AccountDetails) {
    return (user: firebase.User): Observable<firebase.User> => {
      return from(
        user.updateProfile({
          displayName: details.name,
          photoURL: ''
        })
      ).pipe(map(_ => user));
    };
  }

  private signInWithFirebase(
    email: string,
    password: string
  ): Observable<auth.UserCredential> {
    return from(this.afAuth.auth.signInWithEmailAndPassword(email, password));
  }
}

interface AccountDetails {
  name: string;
}
