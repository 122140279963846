import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '@app/services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

const SUPPORTED_LOCALES = ['en', 'es'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private loggedUserSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.loggedUserSubscription = this.authService.loggedUser$.subscribe(
      user => {
        if (!user) {
          this.router.navigate(['login']);
        }
      }
    );
    const detectedLocale = this.getUsersLocale('en-US');
    const supportedRegex = new RegExp('^' + SUPPORTED_LOCALES.join('|^'));
    if (detectedLocale.match(supportedRegex)) {
      this.updateLocale(detectedLocale);
    }
  }

  ngOnDestroy() {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
  }

  private getUsersLocale(defaultValue: string): string {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  private updateLocale(locale: string) {
    const lang = locale.substring(0, 2);
    this.translocoService.setActiveLang(lang);
  }
}
