import { Injectable } from '@angular/core';

import { Observable, from, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

import { LoggedUser } from '@app/models/logged-user';
import { Therapist } from '@app/models/therapist';

const THERAPISTS = 'therapists';

@Injectable({
  providedIn: 'root'
})
export class TherapistsService {
  currentTherapist$: Subject<Therapist> = new ReplaySubject<Therapist>(
    1 /* buffer size */
  );

  private loggedUser$: Subject<LoggedUser> = new ReplaySubject<LoggedUser>(
    1 /* buffer size */
  );
  private therapistDataSubscription: Subscription;

  constructor(private db: AngularFirestore) {
    this.observeLoggedUserChanges();
  }

  /*
   * Public API
   */

  setLoggedUser(loggedUser: LoggedUser) {
    this.loggedUser$.next(loggedUser);
  }

  newTherapist(newUser: LoggedUser): Observable<Therapist> {
    const newTherapist = {
      _id: newUser.id,
      name: newUser.name,
      email: newUser.email
    };
    return from(
      this.collection()
        .doc(newTherapist._id)
        .set(newTherapist)
    ).pipe(map(_ => newTherapist));
  }

  /*
   * Private methods
   */

  private observeLoggedUserChanges() {
    this.loggedUser$.subscribe(loggedUser => {
      if (!loggedUser) {
        return this.handleLogout();
      }
      this.therapistDataSubscription = this.getTherapist(
        loggedUser.id
      ).subscribe(therapist => {
        if (therapist) {
          this.broadcastTherapist(therapist);
        }
      });
    });
  }

  private handleLogout() {
    if (this.therapistDataSubscription) {
      this.therapistDataSubscription.unsubscribe();
    }
    this.broadcastTherapist(null);
  }

  private getTherapist(id: string): Observable<Therapist> {
    return this.collection()
      .doc<Therapist>(id)
      .valueChanges();
  }

  private broadcastTherapist(therapist: Therapist) {
    this.currentTherapist$.next(therapist);
  }

  private collection(): AngularFirestoreCollection<Therapist> {
    return this.db.collection<Therapist>(THERAPISTS);
  }
}
