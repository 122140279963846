// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA6vsaLcTw19umtasQy6y9QpQBOiL7Oiq0',
    databaseURL: 'https://symptoms-dev.firebaseio.com',
    storageBucket: 'symptoms-dev.appspot.com',
    authDomain: 'symptoms-dev.firebaseapp.com',
    messagingSenderId: '956576921484',
    projectId: 'symptoms-dev'
  },
  mapbox: {
    accessToken:
      'pk.eyJ1Ijoic3ltcHRvbXMiLCJhIjoiY2pqeDNuODQ5N3R6MzNwbnp1M2drYWZiYiJ9.ZhYSBq3PhIvjvCMIF-4-AA',
    geocoderAccessToken:
      'pk.eyJ1Ijoic3ltcHRvbXMiLCJhIjoiY2pqeDNuODQ5N3R6MzNwbnp1M2drYWZiYiJ9.ZhYSBq3PhIvjvCMIF-4-AA'
  },
  symptomsAnalytics: {
    host: 'europe-west1-symptoms-dev.cloudfunctions.net',
    protocol: 'https',
    dataEndpoint: 'data',
    analysisEndpoint: 'analysis'
  }
};
